.lift-effect {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px;
  transition: all 0.3s ease-in-out;
}

.lift-effect:hover {
  box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
  transform: translate3d(-5px, -5px, 0px);
}

input,
textarea {
  background-color: #f1f5f9;
}
